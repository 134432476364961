import axios from "axios";

export const isDev = () =>
  !process.env.NODE_ENV || process.env.NODE_ENV === "development";

export const uri = isDev()? 'https://v1.claima.io' : 'https://v1.claima.io';
export const uri_img = 'https://v1.claima.io';

const inDev = isDev();

export const header = {
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/json",
  Version: "1.5.1",
  Application: 3,
  Dev: inDev ? 1 : 0,
};

const Axios = axios.create({ baseURL: uri, headers: header });

export default Axios;
